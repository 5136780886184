import { usePaginationPage } from '@/hooks/usePaginationPage';
import {
  getCarouselImageApi,
  getSubscriptionListApi,
  getTransactionSubscription,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useMount, useUpdateEffect } from 'ahooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * 审核模式（0：普通 1：中签）
 */
export enum AuditModeEnum {
  /**
   * 普通
   */
  NORMAL = 1,

  /**
   * 中签
   */
  WINNING = 2,
}

export enum SubscriptionStatusEnum {
  /**
   * 已申购
   */
  ORDERED = 'ORDERED',

  /**
   * 可申购
   */
  CANORDER = '1',

  /**
   * 即将开始
   */
  UPCOMING = '2',

  /**
   * 已结束
   */
  ENDED = '3',
}

// ipo订单状态
export enum SubscribeOrderStatusEnum {
  /**
   * 待审核
   */
  TO_BE_REVIEWED,

  /**
   * 成功
   */
  SUCCESS,

  /**
   * 失败
   */
  FAIL,

  /**
   * 进行中
   */
  ONGOING,

  /**
   * 结算
   */
  SETTLEMENT,

  /**
   * 结算失败
   */
  SETTLEMENT_FAILED,
}

export const useSubscriptionStateHooks = () => {
  // 使用国际化 hook
  const { t } = useTranslation();

  // 初始化并管理排序关键字的状态
  const [orderKey, setOrderKey] = useState(1);
  // 用于重新加载排序的关键字
  const reloadOrder = () => {
    setOrderKey((old) => old + 1);
  };

  // 初始化并管理过滤关键字的状态
  const [filterText, setFilterText] = useState('');

  // 初始化并管理tab选项的选中状态
  const [tabSelected, setTabSelected] = useState(
    SubscriptionStatusEnum.CANORDER,
  );

  // 定义tab选项列表
  const tabOptions = [
    {
      text: t('可申购'),
      value: SubscriptionStatusEnum.CANORDER,
    },
    {
      text: t('即将开始'),
      value: SubscriptionStatusEnum.UPCOMING,
    },
    {
      text: t('已结束'),
      value: SubscriptionStatusEnum.ENDED,
    },
    {
      text: t('已申购'),
      value: SubscriptionStatusEnum.ORDERED,
    },
  ];

  // 切换tab选项的方法
  const changeTab = (value: SubscriptionStatusEnum) => {
    setTabSelected(value);
    setTimeout(() => {
      reloadOrder();
    }, 0);
  };

  // 初始化并管理请求参数的状态（用于可申购、即将开始、已结束的订阅状态）
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
    partition: tabSelected,
  });

  // 使用自定义hook获取订阅列表（可申购、即将开始、已结束）
  const {
    list: listCanOrder,
    loading: loadingCanOrder,
    loadMore: loadMoreCanOrder,
    error: errorCanOrder,
    total: totalCanOrder,
    getList: getSubscriptionList,
  } = usePaginationPage({
    fn: getSubscriptionListApi,
    params,
    setParams,
  });

  // 计算过滤后的列表
  const filterList = useMemo(() => {
    return listCanOrder?.filter((item: any) => {
      return item?.company?.toLowerCase().includes(filterText?.toLowerCase());
    });
  }, [listCanOrder, filterText]);

  // 初始化并管理请求参数的状态（用于已申购的订阅状态）
  const [orderedParams, setOrderedParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
  });

  // 使用自定义hook获取已申购的订阅列表
  const {
    list: orderedList,
    loading: orderedLoading,
    loadMore: orderedLoadMore,
    error: orderError,
    total: orderedTotal,
  } = usePaginationPage({
    fn: getTransactionSubscription,
    params: orderedParams,
    setParams: setOrderedParams,
  });

  // 计算过滤后的已申购列表
  const orderedFilterList = useMemo(() => {
    return orderedList?.filter((item: any) => {
      if (item.alias === null) return item;
      return item.company.toLowerCase().includes(filterText.toLowerCase());
    });
  }, [orderedList, filterText]);

  // 使用自定义hook获取轮播图数据
  const { data: carousel = [], run: getCarouselImage } = useReq(
    getCarouselImageApi,
    {
      manual: true,
      defaultValue: [],
    },
  );

  // 页面加载时触发获取轮播图数据
  useMount(() => {
    getCarouselImage({ purpose: 3 });
  });

  // 当选项卡选择发生变化时，更新请求参数
  useUpdateEffect(() => {
    if (tabSelected === SubscriptionStatusEnum.ORDERED) {
      setOrderedParams({
        ...params,
      });
      return;
    }
    setParams({
      ...params,
      partition: tabSelected,
    });
  }, [tabSelected]);

  // 返回使用到的状态和函数
  return {
    tabOptions, // tab选项列表
    tabSelected, // tab选项选中状态
    changeTab, // 切换tab选项的方法
    loading: loadingCanOrder || orderedLoading, // 整合加载状态
    loadMore: loadMoreCanOrder || orderedLoadMore, // 整合加载更多函数
    error: errorCanOrder || orderError, // 整合错误状态
    total: totalCanOrder || orderedTotal, // 整合总数
    orderKey, // 关键字的状态
    reloadOrder, // 重新加载排序
    filterList, // 过滤后的列表
    filterText, // 过滤关键字的状态
    setFilterText, // 设置过滤关键字的状态
    orderedFilterList, // 过滤后的已申购列表
    orderedLoading, // 已申购的订阅列表加载状态
    orderedLoadMore, // 已申购的订阅列表加载更多函数
    orderError, // 已申购的订阅列表错误状态
    orderedTotal, // 已申购的订阅列表总数
    carousel, // 轮播图数据
    getSubscriptionList,
  };
};

// 定义useSubscriptionStateHooks函数返回值的类型
export type SubscriptionStateType = ReturnType<
  typeof useSubscriptionStateHooks
>;
