import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import { BaseFlex } from '@/components/base/baseFlex';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseStepper from '@/components/base/baseStepper';
import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import FinanceList from '@/pages/public/market/components/FinanceList';
import { AuditModeEnum } from '@/pages/funds/financeList/useStateHooks';
import RenderUtil from '@/utils/RenderUtil';
import { toJsonData } from '@/utils/socket';
import { history, useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Textarea } from '@nextui-org/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import {
  PageURL,
  SubscribeNoeType,
  useBuySubscribeStateHooks,
} from './useStateHooks';
import FinanceCard from '@/pages/public/market/components/FinanceCard';

export enum ApplyStatus {
  NotStarted = 'NotStarted', // 未开始
  CanApply = 'CanApply', // 可以申请
  Ended = 'Ended', // 认购已结束
}

/**
 *  Ieo 申购
 * 该组件通过 `useTranslation` 钩子获取国际化文本，
 * 并通过 `useBuySubscribeStateHooks` 钩子管理认购购买的相关状态。
 */
export default () => {
  const { t } = useTranslation(); // 获取国际化文本函数

  // 使用useBuySubscribeStateHooks钩子获取购买订阅的相关状态
  const {
    buySubscriptionRequest, // 发起申购请求
    requestLoading, // loading
    setRequestLoading, // loading
    target, // 申购目标
    rechargeAmount, // 购买数量
    setRechargeAmount, // 设置购买数量
    param, // 参数
    setSubscriptionLoading,
    subscriptionLoading,
    createSubscriptionAll,
    IEORequestCreate,
    subscriptionPassword,
    setSubscriptionPassword,
    createSubscriptionRequest,
  } = useBuySubscribeStateHooks();
  const [urlState] = useUrlState();
  let typeConfigJson = toJsonData(target?.typeConfigJson);
  // 判断一键申购类型和返回数量
  const handleBuySubscribe = useMemo(() => {
    if (target?.typeConfigJson) {
      const configJson = JSON.parse(target?.typeConfigJson);
      if (configJson) {
        switch (configJson?.quickSubscriptionType) {
          case SubscribeNoeType.FIXED_VALUE:
            return {
              number: configJson.quickSubscriptionNumber,
              type: configJson.quickSubscriptionType,
            };
          case SubscribeNoeType.PERCENTAGE:
            return {
              number: configJson.quickSubscriptionNumber,
              type: configJson.quickSubscriptionType,
            };
          case SubscribeNoeType.ALL:
            return {
              number: configJson.quickSubscriptionNumber,
              type: configJson.quickSubscriptionType,
            };
        }
      }
    }
  }, [target]);

  const { userAmount } = useModel('user');

  // 标题
  const title = useMemo(() => {
    if (urlState?.type === '2' || urlState?.type === '3') {
      return target?.alias;
    } else {
      return target?.company + t('申购');
    }
  }, [target, urlState?.type]);
  const { coinsFunds, renderFunds } = useModel('user');

  const renderList = useMemo(() => {
    return coinsFunds?.walletList?.map((item: any) => {
      // 查找socket推送的币币列表
      const findItem = renderFunds?.coin?.walletList?.find?.(
        (fundsItem: any) => {
          return fundsItem?.name === item?.name;
        },
      );
      return {
        ...item,
        availableBalance: findItem?.ab ?? item?.availableBalance,
        convertUsd: findItem?.abu ?? item?.convertUsd,
        freezeBalance: findItem?.fb ?? item?.freezeBalance,
      };
    });
  }, [coinsFunds, renderFunds]);

  const availableBalance = useMemo(() => {
    return renderList?.find?.((item:any) => item?.name === 'USDT');
  }, [renderList]);

  const applyStatus = useMemo<ApplyStatus>(() => {
    const currentTime = Date.now();
    if (currentTime < target?.startBuyTime) {
      // 未开始
      return ApplyStatus.NotStarted;
    } else if (
      currentTime >= target?.startBuyTime &&
      currentTime <= target?.stopBuyTime
    ) {
      // 可以用
      return ApplyStatus.CanApply;
    } else {
      // 已结束
      return ApplyStatus.Ended;
    }
  }, [target?.startBuyTime, target?.stopBuyTime]);

  return (
    <div>
      <BaseTopNav
        title={title}
        rightNode={
          <MaterialSymbolsAssignmentRounded
            onClick={() => {
              history.push(PageEnum.IEOORDER);
            }}
            width="1.2em"
            height="1.2em"
            className="cursor-pointer text-foreground pt-1 px-4 box-content"
          />
        }
      />
      {/* 顶部导航组件，标题为目标符号名称加上“申购”文本 */}
      <div className="p-3">
        <FinanceCard item={target} type="detail"/>
        {/*表单区域*/}
        <div className="px-1">
          <BaseFlex justify="between" align="center" flex={1} className="py-2">
            <div className="text-[#161616] text-[18px] font-[18px]">
              {t('购买')}
            </div>
            <div className="text-[12px] text-[#86909C]">
              {t('可用余额') + ' '}
              {RenderUtil.FormatAmount(availableBalance?.availableBalance, 8)}
              {' USDT'}
            </div>
          </BaseFlex>

          {target?.auditMode !== AuditModeEnum.WINNING && (
            <BaseFlex justify="center" align="center" flex={1}>
              <BaseInputNumber
                className="w-full  h-[48px] text-xs"
                step={1}
                classNames={{
                  inputBox: '!w-full',
                }}
                placeholder={
                  t('股份数量') + `(${t('最小购买数')} ${target?.minNum})`
                }
                value={Number(rechargeAmount)}
                onChange={(value:any) => {
                  setRechargeAmount(value);
                }}
              />
            </BaseFlex>
          )}
          {typeConfigJson?.secretKeySubscription === 1 && (
            <BaseFlex
              justify="center"
              align="center"
              className="mt-3 rounded-md bg-backgroundAuxiliaryColor"
              flex={1}
            >
              <Textarea
                value={subscriptionPassword}
                onValueChange={(value) => {
                  setSubscriptionPassword(value);
                }}
                classNames={{
                  base: 'rounded-md bg-[transparent]',
                  inputWrapper: '!bg-backgroundAuxiliaryColor text-center',
                }}
                placeholder={t('请输入密钥')}
              />
            </BaseFlex>
          )}
          <BaseFlex justify="center" align="center" flex={1}>
            <Button
              spinner={<LoadingSvg />}
              // isDisabled={
              //   Number(rechargeAmount) <= 0 &&
              //   target?.auditMode === AuditModeEnum.NORMAL &&
              //   !canApply(target?.startBuyTime, target?.stopBuyTime)
              // }
              isDisabled={
                applyStatus !== ApplyStatus.CanApply ||
                (Number(rechargeAmount) <= 0 &&
                  target?.auditMode === AuditModeEnum.NORMAL)
              }
              className="bg-primary  rounded-md  mt-4 !h-[40px] w-full  text-primaryButtonTextColor"
              onClick={() => {
                if (
                  Number(rechargeAmount) < target?.minNum &&
                  target?.auditMode === AuditModeEnum.NORMAL
                ) {
                  return toast.error(t('最小购买数') + ` ${target?.minNum}`);
                }
                setRequestLoading(true);
                if (urlState?.type === PageURL.IPO.toString()) {
                  return buySubscriptionRequest(param);
                }
                if (urlState?.type === PageURL.IEO.toString()) {
                  return IEORequestCreate(param);
                }
                if (urlState?.type === PageURL.DISTRIBUTION.toString()) {
                  return createSubscriptionRequest(param);
                }
              }}
              isLoading={requestLoading}
            >
              {applyStatus === ApplyStatus.CanApply
                ? t('申请购买')
                : applyStatus === ApplyStatus.Ended
                ? t('申购已结束')
                : t('未到开始购买时间')}
            </Button>
          </BaseFlex>
          <BaseFlex justify="center" align="center" flex={1} className="mt-2">
            {handleBuySubscribe && (
              <Button
                spinner={<LoadingSvg />}
                isDisabled={applyStatus !== ApplyStatus.CanApply}
                className="bg-primary rounded-md text-primaryButtonTextColor !h-[40px] w-full"
                onClick={() => {
                  setSubscriptionLoading(true);
                  createSubscriptionAll({ subscribeConfigId: target?.id });
                }}
                isLoading={subscriptionLoading}
              >
                {applyStatus === ApplyStatus.CanApply
                  ? t('一键认购')
                  : applyStatus === ApplyStatus.Ended
                  ? t('认购已结束')
                  : t('未到开始购买时间')}
              </Button>
            )}
          </BaseFlex>
          {target?.pdfUrl && (
            <BaseFlex justify="center" align="center" flex={1} className="mt-1">
              <Button
                className="h-[40px] !p-0 !m-0 mainColorButton"
                onClick={() => {
                  // 打开新的页面
                  history.push(PageEnum.THIRD_PARTY_WEB_PAGES,{
                    title:t("查看白皮书"),
                    url:target?.pdfUrl
                  })
                }}
              >
                {t('查看白皮书')}
              </Button>
            </BaseFlex>
          )}
        </div>
        <div className="h-[300px] mt-5">
          <BaseStepper
            direction="vertical"
            steps={[
              <div className="flex flex-col" key={1}>
                <span className="text-xs text-left">{t('预告日期')}</span>
                <span className="text-xs">
                  {RenderUtil.formatDate(target?.previewTime,"YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>,
              <div className="flex flex-col" key={2}>
                <span className="text-xs">{t('开始时间')}</span>
                <span className="text-xs">
                  {RenderUtil.formatDate(target?.startBuyTime,"YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>,
              <div className="flex flex-col" key={3}>
                <span className="text-xs">{t('结束时间')}</span>
                <span className="text-xs">
                  {RenderUtil.formatDate(target?.stopBuyTime,"YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>,
              <div className="flex flex-col" key={4}>
                <span className="text-xs">{t('结算时间')}</span>
                <span className="text-xs">
                  {RenderUtil.formatDate(target?.settlementTime,"YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>,
            ]}
            value={0}
          />
        </div>
      </div>
    </div>
  );
};
