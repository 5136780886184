import { BaseFlex } from '@/components/base/baseFlex';
import BaseTextAvatar from '@/components/base/baseTextAvatar';
import RenderUtil from '@/utils/RenderUtil';
import { Progress } from '@nextui-org/react';
import { DataType, PageURL } from '@/pages/public/market/components/FinanceList';
import { useMemo } from 'react';
import PageEnum from '@/enums/pageEnum';
import { toJsonData } from '@/utils/socket';
import { useTranslation } from 'react-i18next';
import { history, useModel } from '@@/exports';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';

export default ({item,type,children}:any)=>{

  const renderCardType = useMemo(() => {
    if (type === 'list' || type === 'detail') {
      return 'border-1 border-borderColor p-3 rounded-xl mb-2.5';
    }
    if (type === 'order') {
      return 'w-full mb-2.5';
    }
  }, [type]);
  const typeConfigJson = toJsonData(item?.typeConfigJson);
  const { t } = useTranslation();

  const { joinSymbol } = useModel('exchange');
  const handleSubscribeType = (item: any) => {
    const typeMap: any = {
      [DataType.IPO]: {
        path: PageEnum.BUYSUBSCRIBE,
        queryParam: PageURL.IPO,
        warningMessage: t('申购还未开始'),
      },
      [DataType.IEO]: {
        path: PageEnum.BUYSUBSCRIBE,
        queryParam: PageURL.IEO,
        warningMessage: t('IEO申购还未开始'),
      },
      [DataType.DISTRIBUTION]: {
        path: PageEnum.BUYSUBSCRIBE,
        queryParam: PageURL.DISTRIBUTION,
        warningMessage: t('配售还未开始'),
      },
    };

    const { startBuyTime, stopBuyTime, type } = item;

    return history.push(
        `${typeMap[type].path}?type=${typeMap[type].queryParam}&tabType=${GetCoinListTypeEnum.IEO}`,
        { target: item },
    );
  };

  // 判断当前的时间是否在申购时间内
  const canApply = (startBuyTime: number, stopBuyTime: number) => {
    const currentTime = Date.now();
    return currentTime >= startBuyTime && currentTime <= stopBuyTime;
  };


  return <div>
    <div
        className={renderCardType}
        key={item?.id}
        onClick={() => {
          if (type === 'detail') return;
          handleSubscribeType(item);
        }}
    >
      <BaseFlex justify="between" align="center" flex={1}>
        <BaseFlex justify="start" align="center" flex={1} vertical>
          {item?.type === DataType.IPO && (
              <>
                <div className="w-full text-[16px] font-[600]">
                  {item?.company}
                </div>
                <div className="w-full text-[12px]">{item?.alias}</div>
              </>
          )}
          {(item?.type === DataType.IEO ||
              item?.type === DataType.DISTRIBUTION) && (
              <div className="w-full flex gap-1 items-center">
                      <span className="text-[16px] mb-1 font-[600]">
                        {item?.alias}
                      </span>
                <span className="font-normal bg-backgroundAuxiliaryColor rounded-[4px] p-1 text-xs">
                        {typeConfigJson?.ieoType === 0
                            ? t('普通申购')
                            : t('机构配售')}
                      </span>
              </div>
          )}
        </BaseFlex>
        <BaseFlex
            justify="start"
            align="center"
            className="flex-shrink-0"
        >
          {item?.icon1 ? (
              <img
                  src={item?.icon1}
                  className="w-[44px] h-[44px] rounded-lg"
              />
          ) : (
              <BaseTextAvatar
                  name={item.company}
                  className="w-[44px] h-[44px] rounded-lg flex justify-center items-center"
              />
          )}
        </BaseFlex>
      </BaseFlex>
      {(type === 'list' || type === 'order') && (
          <>
            <BaseFlex
                justify="between"
                align="center"
                flex={1}
                className="mt-2"
            >
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('售卖')}
                </div>
                <div className="w-full text-[#00B42A] text-[16px] font-[600]">
                  {joinSymbol({
                    price: RenderUtil.FormatAmount(item?.price, 2),
                    currency: item?.coinName,
                  })}
                </div>
              </BaseFlex>
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('融资总额')}
                </div>
                <div className="w-full text-[14px]">
                  {joinSymbol({
                    price: RenderUtil.FormatAmount(
                        item.issuesNumber * item.price * item.firstHand,
                        2,
                    ),
                    currency: item?.coinName,
                  })}
                </div>
              </BaseFlex>
            </BaseFlex>
            <BaseFlex
                justify="between"
                align="center"
                flex={1}
                className="mt-2"
            >
              {/* 时间组 包含的显示结束日期 ， 时间组不包含的显示 开始日期  */}

              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('结束日期')}
                </div>
                <div className="w-full text-[14px]">
                  {RenderUtil.formatDate(item?.stopBuyTime)}
                </div>
              </BaseFlex>

              {/* {canApply(item?.startBuyTime, item?.stopBuyTime) ? (
                    <BaseFlex justify="start" align="center" flex={1} vertical>
                      <div className="w-full text-[#86909C] text-[12px]">
                        {t('结束日期')}
                      </div>
                      <div className="w-full text-[14px]">
                        {RenderUtil.formatDate(item?.stopBuyTime)}
                      </div>
                    </BaseFlex>
                  ) : (
                    <BaseFlex justify="start" align="center" flex={1} vertical>
                      <div className="w-full text-[#86909C] text-[12px]">
                        {t('开始日期')}
                      </div>
                      <div className="w-full text-[14px]">
                        {RenderUtil.formatDate(item?.startBuyTime)}
                      </div>
                    </BaseFlex>
                  )} */}

              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full flex justify-between items-center">
                        <span className="text-[#86909C] text-[12px]">
                          {t('融资进度')}
                        </span>
                  <span className="text-[12px] text-[#2F82FF]">
                          {RenderUtil.formatNum(
                              (item?.soldQuantity / item?.issuesNumber) * 100,
                          )}{' '}
                    %
                        </span>
                </div>
                <div className="w-full h-[21px] flex justify-center items-center">
                  <Progress
                      value={RenderUtil.formatNum(
                          (item?.soldQuantity / item?.issuesNumber) * 100,
                      )}
                      size="sm"
                      radius="sm"
                  />
                </div>
              </BaseFlex>
            </BaseFlex>
          </>
      )}
      {type === 'detail' && (
          <>
            <BaseFlex
                justify="between"
                align="center"
                flex={1}
                className="mt-2"
            >
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('发行价格')}
                </div>
                <div className="w-full text-[#00B42A] text-[16px] font-[600]">
                  {joinSymbol({
                    price: RenderUtil.FormatAmount(item.price, 2),
                    currency: item?.coinName,
                  })}
                </div>
              </BaseFlex>
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('最小购买数')}
                </div>
                <div className="w-full text-[14px]">{item?.minNum}</div>
              </BaseFlex>
            </BaseFlex>
            <BaseFlex
                justify="between"
                align="center"
                flex={1}
                className="mt-2"
            >
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('总发行股数')}
                </div>
                <div className="w-full text-[14px]">
                  {item.issuesNumber}
                </div>
              </BaseFlex>
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full text-[#86909C] text-[12px]">
                  {t('融资总额')}
                </div>
                <div className="w-full text-[14px]">
                  {joinSymbol({
                    price: RenderUtil.FormatAmount(
                        item.issuesNumber * item.price * item.firstHand,
                        2,
                    ),
                    currency: item?.coinName,
                  })}
                </div>
              </BaseFlex>
            </BaseFlex>
            <BaseFlex
                justify="between"
                align="center"
                flex={1}
                className="mt-2"
            >
              {canApply(item?.startBuyTime, item?.stopBuyTime) ? (
                  <BaseFlex
                      justify="start"
                      align="center"
                      flex={1}
                      vertical
                  >
                    <div className="w-full text-[#86909C] text-[12px]">
                      {t('结束日期')}
                    </div>
                    <div className="w-full text-[14px]">
                      {RenderUtil.formatDate(item?.stopBuyTime)}
                    </div>
                  </BaseFlex>
              ) : (
                  <BaseFlex
                      justify="start"
                      align="center"
                      flex={1}
                      vertical
                  >
                    <div className="w-full text-[#86909C] text-[12px]">
                      {t('开始日期')}
                    </div>
                    <div className="w-full text-[14px]">
                      {RenderUtil.formatDate(item?.startBuyTime)}
                    </div>
                  </BaseFlex>
              )}
              <BaseFlex justify="start" align="center" flex={1} vertical>
                <div className="w-full flex justify-between items-center">
                        <span className="text-[#86909C] text-[12px]">
                          {t('融资进度')}
                        </span>
                  <span className="text-[12px] text-[#F18F2F]">
                          {RenderUtil.formatNum(
                              (item?.soldQuantity / item?.issuesNumber) * 100,
                          )}{' '}
                    %
                        </span>
                </div>
                <div className="w-full h-[21px] flex justify-center items-center">
                  <Progress
                      value={RenderUtil.formatNum(
                          (item?.soldQuantity / item?.issuesNumber) * 100,
                      )}
                      size="sm"
                      radius="sm"
                  />
                </div>
              </BaseFlex>
            </BaseFlex>
          </>
      )}

      {children && (
          <BaseFlex
              justify="end"
              align="center"
              flex={1}
              className="mt-2"
          >
            {children && children(item).map((it:any) => it)}
          </BaseFlex>
      )}
    </div>
  </div>
}