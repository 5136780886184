import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface AvatarProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  name: string;
  color?: string;
}

export default function BaseTextAvatar(props: AvatarProps) {
  const { name, color = 'rgba(233, 241, 255, 1)', ...reset } = props;

  const firstLetter = name?.charAt(0) ?? 'A';

  const getInvertedColor = (color: string) => {
    const rgbColor = color.replace(/[^\d,]/g, '').split(',');
    const invertedColor = rgbColor.map((channel) => 255 - parseInt(channel));
    return `rgb(${invertedColor.join(',')})`;
  };

  const invertedColor = getInvertedColor(color);

  return (
    <div
      className="flex items-center justify-center w-8 h-8 rounded-lg"
      style={{
        backgroundColor: color,
        border: '1px solid ' + color,
        color: invertedColor,
        fontSize: '14px',
      }}
      {...reset}
    >
      <span className="text-sm">{firstLetter}</span>
    </div>
  );
}
