import PullToRefresh from '@/components/base/basePullToRefresh';
import {useEffect, useState} from 'react';
import FinanceListCard from "./FinanceCard"
import {usePaginationPage} from '@/hooks/usePaginationPage';
import {getIEOPageList} from '@/services/api/requestApi';
import {useTranslation} from "react-i18next";
import BaseTabs from "@/components/base/baseTabs";
import {useUpdateEffect} from "ahooks";

// 参数判断ipo还是ieo
export enum PageURL {
  IPO = 1,
  IEO = 2,
  DISTRIBUTION = 3,
}

// 数据类型
export enum DataType {
  IPO = 2,
  IEO = 4,
  DISTRIBUTION = 5,
}

enum IEOType {
  ORDINARYSUBSCRIPTION = 0,
  INSTITUTIONALPLACEMENTS = 1,
}

export default function FinanceListList({setLoadingDisplay}:{ setLoadingDisplay?:(val:boolean)=>void }) {
  const {t} = useTranslation();
  //IEO列表配置
  const tabOptions = [
    {text: t('普通申购'), value: IEOType.ORDINARYSUBSCRIPTION},
    {text: t('机构配售'), value: IEOType.INSTITUTIONALPLACEMENTS},
  ];
  // 默认选中的
  const [tabSelected, setTabSelected] = useState(IEOType.ORDINARYSUBSCRIPTION);

  const [ieoParams, setIeoParams] = useState<any>({
    pageNo: 1,
    pageSize: 10,
    partition: '4',
    ieoType: tabSelected,
  });

  useUpdateEffect(() => {
    setIeoParams({
      ...ieoParams,
      ieoType: tabSelected,
    });
  }, [tabSelected]);

  const {
    list,
    loading,
    loadMore,
    getList,
    total,
    error,
    ...reset
  } = usePaginationPage({
    fn: getIEOPageList,
    params: ieoParams,
    setParams: setIeoParams,
  });
  useEffect(()=>{
    if (setLoadingDisplay) {
      setLoadingDisplay?.(loading);
    }
  },[loading])
  return (
      <>
        <div className="mb-2 w-full">
          <BaseTabs
              value={tabSelected}
              onChange={setTabSelected}
              options={tabOptions}
          />
        </div>
        <PullToRefresh
            loadMore={loadMore}
            total={total}
            list={list}
            loading={loading}
            getList={getList}
            loadingDisplay={false}
            {...(reset as any)}
        >
          {list?.map((item: any) => {
            return (
                <>
                  <FinanceListCard item={item} type="list" key={item?.id}/>
                </>
            );
          })}
        </PullToRefresh>
      </>
  );
}
