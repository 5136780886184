import {
  buySubscription,
  buySubscriptionAll,
  createIEOOrderApi,
  createSubscriptionApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history, useModel } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { useLocation } from 'umi';

export enum SubscribeNoeType {
  //   固定值购买
  FIXED_VALUE,
  //   百分比购买
  PERCENTAGE,
  //   所有
  ALL,
}

// 参数判断ipo还是ieo
export enum PageURL {
  IPO = 1,
  IEO = 2,
  DISTRIBUTION = 3,
}

export const useBuySubscribeStateHooks = () => {
  const { t } = useTranslation();
  const { state } = useLocation() as any;

  /**
   * 申购目标
   */
  const { target } = state || {};

  /**
   * 购买数量
   */
  const [rechargeAmount, setRechargeAmount] = useState('');

  /**
   * 购买loading
   */
  const [requestLoading, setRequestLoading] = useState(false);
  const [subscriptionPassword, setSubscriptionPassword] = useState('');

  /**
   * 一键认购loading
   */
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  /**
   * 参数
   */
  const param = useMemo(() => {
    return {
      subscribeConfigId: target?.id,
      buyNum: rechargeAmount,
      secretKey: subscriptionPassword,
    };
  }, [rechargeAmount, subscriptionPassword]);
  const { refreshWallet } = useModel('user');
  /**
   * 发起申购请求
   */
  const { runAsync: buySubscriptionRequest } = useReq(buySubscription, {
    manual: true,
    loadingDefault: false,
    onFinally: () => {
      setRequestLoading(false);
    },
    onSuccess: () => {
      // 成功后的操作
      toast.success(t('购买成功'));
      refreshWallet();
      history.back();
    },
  });

  /**
   * ieo 请求购买
   */
  const { runAsync: IEORequestCreate } = useReq(createIEOOrderApi, {
    manual: true,
    loadingDefault: false,
    onFinally: () => {
      setRequestLoading(false);
    },
    onSuccess: () => {
      toast.success(t('购买成功'));
      refreshWallet();
      history.back();
    },
  });

  /**
   * 配售购买
   *
   */

  const { runAsync: createSubscriptionRequest } = useReq(
    createSubscriptionApi,
    {
      manual: true,
      loadingDefault: false,
      onFinally: () => {
        setRequestLoading(false);
      },
      onSuccess: () => {
        toast.success(t('购买成功'));
        refreshWallet();
        history.back();
      },
    },
  );

  /**
   * 一键认购
   */
  const { runAsync: createSubscriptionAll } = useReq(buySubscriptionAll, {
    manual: true,
    loadingDefault: false,
    onFinally: () => {
      setSubscriptionLoading(false);
    },
    onSuccess: () => {
      toast.success(t('购买成功'));
      refreshWallet();
      history.back();
    },
  });

  return {
    buySubscriptionRequest, // 发起申购请求
    setRequestLoading, // loading
    requestLoading, // loading
    target, // 申购目标
    rechargeAmount, // 购买数量
    setRechargeAmount, // 设置购买数量
    param, // 参数
    subscriptionLoading, // 一键认购loading
    setSubscriptionLoading, // 一键认购 loading
    createSubscriptionAll, // 一键认购
    IEORequestCreate,
    createSubscriptionRequest,
    subscriptionPassword,
    setSubscriptionPassword,
  };
};
